import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoicingService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiService: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  getInvoiceList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    year?: string,
    invoiceDate?: string,
    projectId?: string,
    cost_centerID?: string,
    invoiceStatus?: string,
    type?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/invoice/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;

    if (invoiceDate) {
      path += `&invoiceDate=${invoiceDate}`;
    }

    if (year) {
      path += `&year=${year}`;
    }

    if (projectId) {
      path += `&projectId=${projectId}`;
    }

    if (cost_centerID) {
      path += `&cost_centerID=${cost_centerID}`;
    }

    if (invoiceStatus) {
      path += `&invoiceStatus=${invoiceStatus}`;
    }

    if (type) {
      path += `&type=${type}`;
    }

    return this.apiService.get(path);
  }

  getInvoiceItemListById(invoiceID: string): Observable<any> {
    const path = `${this.baseUrl}/invoice/${invoiceID}`;
    return this.apiService.get(path);
  }

  getBankDetails(): Observable<any> {
    const path = `${this.baseUrl}/bank-detail`;
    return this.apiService.get(path);
  }

  getInvoiceYear() {
    const path = `${this.baseUrl}/invoice/years`;
    return this.apiService.get(path);
  }

  createInvoiceExpense(formData: any): Observable<any> {
    let path = `${this.baseUrl}/invoice/invoice-item`;
    return this.apiService.post(path, formData);
  }

  updateInvoiceExpense(expenseData: any): Observable<any> {
    const url = `${this.baseUrl}/invoice/invoice-item`;
    return this.apiService.put(url, expenseData).pipe(
      catchError((error: any) => {
        console.error('Error updating expenses:', error);
        throw error;
      })
    );
  }

  getActivityLogs(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: '' | 'ASC' | 'DESC' = ''
  ): Observable<any> {
    const path = `${this.baseUrl}/auth/activity-logs?module=invoice&module=invoice_item&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiService.get(path);
  }

  updatePaymentStatus(statusData: {
    invoiceID: string;
    invoiceStatus: string;
  }): Observable<any> {
    const url = `${this.baseUrl}/invoice/status`;
    return this.apiService.put(url, statusData).pipe(
      catchError((error: any) => {
        console.error('Error updating status:', error);
        return throwError(() => {});
      })
    );
  }

  getInvoiceStatus(): Observable<any> {
    const path = `${this.baseUrl}/master/invoices-status`;
    return this.apiService.get(path);
  }

  getLastInvoiceGeneratedDate(projectType: string): Observable<any> {
    let type: string;

    switch (projectType) {
      case 'project_daily':
        type = 'project_daily';
        break;
      case 'project_hourly':
        type = 'project_hourly';
        break;
      case 'project_fixed':
        type = 'project_fixed';
        break;
      default:
        console.error('Unknown project type:', projectType);
        return throwError(() => 'Unknown project type');
    }

    const path = `${this.baseUrl}/invoice/date?type=${type}`;
    return this.apiService.get(path);
  }
}
